@keyframes fadein { 
  from { 
    opacity: 0; 
  } to {
    opacity: 1; 
  }
}

@keyframes fadeout { 
  from {
    opacity: 1; 
  } to {
    opacity: 0; 
  } 
}

html {
  /* 결과 → 16px × 62.5% = 10px */
  font-size: 62.5%;
}

body {
  font-family: 'Pretendard';
  font-style: normal;
  font-size: 2rem;
  line-height: normal;
}

.hide {
  display: none;
}

.pc-tag.hide {
  display: inherit;
}

.table-wrap .table-box > div.pc-tag.hide {
  display: flex;
}

.mw-tag.hide {
  display: none;
}

.table-wrap .table-box > div.mw-tag.hide {
  display: none;
}

main.caring .main-body {
  padding: unset;
}

.display-block {
  display: block !important;
}

/* 스크롤바 제거 */
/* body::-webkit-scrollbar {
  display: none;
} */

button {
  box-sizing: border-box;
  background: rgba(34, 34, 34, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 16px;
  font-family: 'Pretendard';
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 600;
  padding: 16px 36px;
  animation: zoom-in;
}

button:hover {
  cursor: pointer;
  background: rgba(34, 34, 34, 0.70);
  animation: zoom-in;
}

/* animation */
footer,
footer div,
button, 
.product-list-wrap img,
header .mw-header-wrap,
main .contents .box-wrap > div,
main .product-wrap div.product-wrap-list img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

html a {
  display: block;
  text-decoration: none;
  color: initial;
  width: fit-content;
}

/* header */
header {
  padding: 24px 14%;
  position: sticky;
  top: 0;
  z-index: 10;
}

header > div {
  display: flex;
  justify-content: center;
  background: rgba(255, 255, 255, 0.90);
  border-radius: 24px;
  padding: 0 50px;
  height: 80px;
}

header > div > div {
  display: flex;
  align-items: center;
}

header > div .logo-wrap {
  width: 80px;
  flex-shrink: 0;
}

header > div .logo-wrap img {
  width: 100%;
}

header .text-wrap {
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  color: #222222;
  font-weight: 600;
}

header .text-wrap .header-link-wrap {
  display: flex;
  gap: 60px;
}

header .text-wrap .header-link-wrap a.active {
  color: #73BE44;
}

/* footer */
footer {
  background: #DEE9D7;
}

footer > div {
  display: flex;
  height: 300px;
  padding: 0 100px;
}

footer > div > div {
  display: flex;
  align-items: center;
}

footer > div .logo-wrap {
  width: 200px;
  flex-shrink: 0;
  margin-right: 20px;
}

footer > div .logo-wrap img {
  width: 100%;
}

footer .text-wrap {
  flex-direction: row;
  justify-content: flex-end;
  gap: 50px;
  width: 100%;
  color: #8FA680;
  font-size: 14px;
  font-weight: 400;
}

footer .text-wrap .title {
  font-family: 'Noto Sans KR';
  color: #6F8F5B;
  font-weight: 600;
}

footer .text-wrap > div {
  line-height: 23px;
}

footer .text-wrap .company-wrap {
  margin-top: 23px;
}

footer .store, footer .location {
  background-image: url("./images/icon/store_icon.png");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  padding-left: 24px;
}

footer .title.location {
  background-image: url("./images/icon/location_icon.png");
}

/* contents */
main {
  margin-top: -128px;
}

main .green-color-title {
  color: #72BF44;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
}

main .contents {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  height: 100%;
}

main .contents .box-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
}

main .contents .box-wrap > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 40px;
  width: 608px;
  height: 500px;
}

main .contents .box-wrap > div:hover {
  border-radius: 140px;
}

main .contents div p + p {
  margin-top: 24px;
}

main .contents .title {
  font-weight: 700;
  font-size: 36px;
  color: #FFFFFF;
}

main .contents > div + div {
  margin-top: 60px;
}

main .top-wrap {
  display: flex;
  justify-content: center;
  background-image: url("./images/mainBanner/main_banner.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 700px;
}

main.home .top-wrap {
  height: 100vh;
}

main.blueberry .main-body {
  padding: unset
}

main.blueberry .main-body img,
main.caring .main-body img {
  display: block;
  border-radius: 16px;
  width: 100%;
  object-fit: cover;
}

main .main-body .padding-wrap {
  padding: 100px 14%;
}

.sub-padding-wrap + img {
  margin-top: 40px;
}

img + .sub-padding-wrap {
  margin-top: 80px;
}

main.blueberry .top-wrap {
  background-image: url("./images/mainBanner/blueberry_banner.webp");
}

main.caring .top-wrap {
  background-image: url("./images/mainBanner/caring_banner.webp");
  background-position-y: -472px;
}

main.shop .top-wrap {
  background-image: url("./images/mainBanner/shop_banner.png");
}

main .top-wrap .main-text-wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  font-weight: 400;
  font-size: 30px;
  padding-top: 104px;
}

main .top-wrap .main-text-wrap .title {
  color: #FFFFFF;
  font-weight: 600;
  font-size: 36px;
}

main .top-wrap .main-text-wrap .title + p,
main .top-wrap .main-text-wrap p + button {
  margin-top: 24px;
}

main .top-wrap .main-text-wrap p + p {
  margin-top: 10px;
}

main .about-wrap {
  background: #FFF9EF;
  height: 864px;
}

main .about-wrap .blueberry-wrap {
  background-image: url("./images/blueberry.jpg");
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

main .about-wrap .caring-wrap {
  background-image: url("./images/caring.jpg");
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

main .about-wrap .green-color-title + p {
  font-size: 30px;
}

main .product-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
}

main .product-wrap > div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
}

main .product-wrap > div .sub-title {
  font-size: 40px;
  font-weight: 700;
}

main .product-wrap > div button {
  background: #DEE9D7;
  color: #222;
}

main .product-wrap > div > p + p {
  line-height: 140%;
}

main .product-wrap > div > p + p,
main .product-wrap > div > p + button {
  margin-top: 24px;
}

main .product-wrap div.product-wrap-list {
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-start;
  width: 1100px;
  font-size: 24px;
  gap: 40px;
  margin-top: 40px;
}

main .product-wrap div.product-wrap-list img {
  display: block;
  width: 100%;
  border-radius: 40px;
}

main .product-wrap div.product-wrap-list p {
  margin-top: 24px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 340px !important;
  height: 340px !important;
}

.main-body {
  padding: 80px 0;
}

main.home .product-list-wrap {
  padding-left: 15%;
}

.product-list-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 40px;
}

.product-list-wrap img:hover,
main .product-wrap div.product-wrap-list img:hover {
  border-radius: 100px;
}

.product-main-wrap {
  display: flex;
  justify-content: center;
}

.product-main-wrap .product-list-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1240px;
}

.product-main-wrap .product-list-wrap img {
  height: unset;
  display: block;
  max-width: 346px;
}

.product-main-wrap .product-list-wrap > div {
  display: flex;
  justify-content: center;
  width: 380px;
  margin-bottom: 50px;
  position: relative;
}

.product-list-wrap img + p {
  margin-top: 16px;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 줄 수 설정 */
  overflow: hidden;
  text-overflow: ellipsis; /* 생략 부호 추가 */
}

.pagination { 
  display: flex; 
  justify-content: center; 
  margin-top: 30px;
}

ul { 
  list-style: none; 
  padding: 0; 
}

ul.pagination li {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  text-align: center;
}

ul.pagination li + li { 
  margin-left: 10px; 
}

ul.pagination li a { 
  color: #AAA; 
  text-decoration: none; 
  width: 20px;
  line-height: 20px;  
}

ul.pagination li.active a {
  color: #000; 
}

ul.pagination li a:hover, ul.pagination li a.active { 
  color: #000;
}

.sub-title {
  color: #222;
  font-size: 36px;
  font-weight: 600;
  text-align: center;
}

.sub-title.lead-icon > span {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}

.sub-title.lead-icon > span::before {
  content: "";
  display: block;
  background-image: url("./images/icon/lead_icon.svg");
  background-repeat: no-repeat;
  position: relative;
  background-size: 40px;
  width: 40px;
  height: 40px;
}

.sub-title + .sub-contents,
.sub-title.lead-icon + .sub-contents,
.sub-title.lead-icon + .sub-sub-title {
  margin-top: 40px;
}

.sub-title.lead-icon + .sub-contents li {
  position: relative;
}

.sub-title.lead-icon + .sub-contents li::before {
  content: "-";
  position: absolute;
  top: -4px;
}

.sub-title.lead-icon + .sub-contents li > p {
  padding-left: 16px;
}

.table-wrap .table-box .table-sub-contents li {
  list-style-type: disc;
  margin-left: 24px;
}

.table-wrap .table-box .table-sub-contents li > p {
  padding-left: 10px;
}

.sub-contents {
  color: #222;
  font-family: "Noto Sans CJK KR";
  font-size: 30px;
  font-weight: 400;
  line-height: 150%; /* 36px */
}

.sub-contents + .notice-wrap {
  margin-top: 16px;
}

.sub-sub-title {
  color: #222;
  font-size: 30px;
  font-weight: 600;
}

.sub-sub-title + .sub-contents {
  margin-top: 16px;
}

.sub-contents + img,
.notice-wrap + img {
  margin-top: 40px;
}

img + .sub-title {
  margin-top: 80px;
}

.table-wrap .table-header {
  display: flex;
  width: calc(100% - 48px);
  margin-top: 24px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: rgba(132, 198, 91, 0.20);
  color: #222;
  font-size: 30px;
  font-weight: 700;
  line-height: 150%;
}

.table-wrap .table-header + .table-box {
  margin-top: 24px;
}

.table-wrap .table-header .table-icon {
  display: flex;
  padding: 0px 16px;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: #84C65B;
  color: var(--Background-Footer, #FFF);
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%; /* 30px */
}

.table-wrap .table-box > div {
  display: flex;
  padding: 24px 40px;
  flex-direction: row;
  border-radius: 16px;
  border: 1px solid #EAEAEA;
  background-color: white;
  align-items: flex-start;
  gap: 24px;
  font-size: 30px;
  font-weight: 500;
}

.table-wrap .table-box.align-center > div {
  align-items: center;
}

.table-wrap .table-box.four-box {
  display: flex;
  align-items: flex-start;
  gap: 9px;
  width: 100%;
}

.table-wrap .table-box.four-box.full-wrap {
  align-items: unset;
}

.table-wrap .table-box.four-box > div {
  width: 292px;
  flex-basis: 0;
  flex-grow: 1;
}

.table-wrap .table-box.four-box > div.middle-size {
  flex-grow: 2;
}

.table-wrap .table-box > div p + p {
  margin-top: 12px;
}

.table-wrap .table-box > div > p > span {
  margin-left: 24px;
}

.table-wrap .table-box > div span {
  color: #222;
}

.table-wrap .table-box > div > .table-sub-title {
  color: #73BE44;
  font-size: 30px;
  font-weight: 500;
}

.table-wrap .table-box > div .table-sub-contents {
  color: #222;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%; /* 30px */
  margin-top: 8px;
}

.table-wrap .table-box > div ul {
  padding-left: 12px;
  margin-top: 12px;
}

.table-wrap .table-box > div ul.custom-circle-list {
  color: #222;
}

.table-wrap .table-box > div ul.custom-circle-list li {
  position: relative;
}

.table-wrap .table-box > div ul.custom-circle-list li:before {
  content: ""; /* 비어 있는 내용 */
  position: absolute;
  left: -12px; /* 원을 왼쪽으로 위치시킴 */
  top: 50%; /* 수직으로 가운데 정렬 */
  transform: translateY(-50%); /* 정확하게 가운데 정렬 */
  width: 3px; /* 원의 크기 */
  height: 3px; /* 원의 크기 */
  background-color: #222; /* 원의 색상 */
  border-radius: 50%; /* 원 모양으로 만듬 */
  border: 1px solid #222;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); /* 깨끗한 그림자 효과 */
}

.highlight {
  display: inline;
  box-shadow: inset 0 -17px 0 #E6F4DE; 
}


.normal-wrap {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
}

.normal-wrap:last-child {
  margin-bottom: unset;
}

.normal-wrap > div:first-child {
  width: 60%;
}

.normal-wrap > div:last-child {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 40%;
}

.normal-wrap.half-and-half > div {
  width: 50%;
}

.masonry-wrap {
  column-count: 2;
  column-gap: 30px;
}

.masonry-wrap > div {
  display: inline-flex;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 10px;
  overflow: hidden;
  flex-direction: column;
}

.blueberry-experience-wrap .masonry-wrap > div > img,
.blueberry-experience-wrap .normal-wrap > div > img,
main.caring .masonry-wrap > div > img {
  height: 850px;
}

.blueberry-experience-wrap .masonry-wrap > div:nth-child(2) > img,
.blueberry-experience-wrap .masonry-wrap > div:last-child > img,
main.caring .masonry-wrap > div:nth-child(1) > img,
main.caring .masonry-wrap > div:last-child > img {
  height: 500px;
}

.green-wrap {
  background: rgba(114, 191, 68, 0.05);
}

.notice-wrap {
  text-align: center;
  margin-top: 80px;
}

.notice-wrap > p.notice-title {
  color: #6F8F5B;
  font-size: 30px;
}

/* 블루베리 농장 */
.normal-wrap.half-and-half + p,
.blueberry-seedlings-sale-wrap > div img + p {
  text-align: center;
}

.blueberry-experience-wrap .normal-wrap:first-child img {
  height: unset;
}

.blueberry-experience-wrap > div p,
.blueberry-seedlings-sale-wrap > div img + p,
.masonry-wrap p {
  color: #92A992;
  font-size: 24px;
  font-weight: 500;
  line-height: 150%; /* 27px */
}

.blueberry-seedlings-sale-wrap .normal-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 36px;
}


.blueberry-seedlings-sale-wrap .normal-wrap p {
  margin-top: 16px;
}

.blueberry-experience-wrap > div > div > div > p,
.masonry-wrap p {
  margin-top: 12px;
  text-align: center;
}

.blueberry-seedlings-sale-wrap .normal-wrap > div {
  width: calc(100% / 2 - 40px);
  position: relative;
}

.blueberry-seedlings-sale-wrap .normal-wrap > div img {
  aspect-ratio: 1;
}

/* 농촌 돌봄 농장 */
.caring .table-wrap,
.caring .table-wrap + div {
  margin-top: 40px;
}
.caring div.farming-introduction-wrap {
  margin-top: 100px;
}

.caring-farm-top-wrap .notice-wrap {
  text-align: left;
}

.caring-farm-top-wrap .main-image {
  height: 700px;
}

.caring-farm-top-wrap .masonry-wrap > div:nth-child(2),
.caring-farm-top-wrap .masonry-wrap > div:last-child {
  margin-bottom: unset;
}

.caring .caring-farm-bottom-wrap .table-wrap,
.caring .caring-farm-bottom-wrap .table-wrap .table-header {
  margin-top: unset;
}

.caring .caring-farm-bottom-wrap .table-wrap .table-box > div .table-sub-title > p {
  width: 170px;
}

.caring .caring-farm-bottom-wrap .table-wrap .table-box > div .custom-import-text {
  position: relative;
  padding-left: 24px;
}

.caring .caring-farm-bottom-wrap .table-wrap .table-box > div .custom-import-text::before {
  content: "※"; /* 비어 있는 내용 */
  position: absolute;
  left: 0px;
  width: 24px;
}

.caring .caring-farm-bottom-wrap .notice-wrap {
  margin-top: 80px;
}

.caring .caring-farm-bottom-wrap.padding-wrap {
  padding-bottom: 80px;
}

.sub-title + .left-title-wrap {
  margin-top: 60px;
}

.mountain-wrap {
  display: flex;
  justify-content: center;
}

.left-title-wrap {
  display: flex;
  justify-content: center;
}

.left-title-wrap > div > div {
  display: flex;
}

.left-title-wrap div > span {
  font-size: 3rem;
  color: #72BE44;
  font-weight: 500;
  line-height: 150%; /* 36px */
  min-width: 180px;
}

.left-title-wrap div .add-text-wrap {
  font-weight: 400;
}

.left-title-wrap div > span:last-child {
  color: #222;
  font-weight: 500;
}

.left-title-wrap div + div {
  margin-top: 10px;
}

header > div > div.burger-sticks {
  position: absolute;
  flex-direction: column;
  top: 20px;
  gap: 5px;
  right: 0;
  padding-right: 32px;
}

header.is-active > div > div.burger-sticks {
  top: 20px;
  transform: unset;
  align-items: unset;
}

.burger-sticks:hover {
  cursor: pointer;
}

.burger-sticks span {
  width: 24px;
	height: 2px;
	background-color: black;
	display: block;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
  border-radius: 10px;
}

/* 햄버거 메뉴 두번째 라인 투명도 100% */
.is-active .mw-header-wrap .burger-sticks span:nth-child(2) {
  opacity: 0;
}

.is-active .mw-header-wrap .burger-sticks span:nth-child(1) {
	-webkit-transform: translateY(7px) rotate(45deg);
	-ms-transform: translateY(7px) rotate(45deg);
	-o-transform: translateY(7px) rotate(45deg);
	transform: translateY(7px) rotate(45deg);
}

.is-active .mw-header-wrap .burger-sticks span:nth-child(3) {
	-webkit-transform: translateY(-7px) rotate(-45deg);
	-ms-transform: translateY(-7px) rotate(-45deg);
	-o-transform: translateY(-7px) rotate(-45deg);
	transform: translateY(-7px) rotate(-45deg);
}

/* 모바일 + 태블릿 800px 기준 */
@media (max-width: 1080px) {

  main.caring .top-wrap {
    background-position-y: unset;
  }

  .pc-tag.hide {
    display: none;
  }

  .table-wrap .table-box > div.pc-tag.hide {
    display: none;
  }

  .mw-tag.hide {
    display: inherit;
  }

  strong {
    display: inline;
    font-weight: bold;
  }

  .table-wrap .table-box > div.mw-tag.hide {
    display: flex;
  }
  
  header {
    padding: 0 16px;
  }

  header .pc-header-wrap {
    display: none;
  }

  header .mw-header-wrap {
    display: flex;
    position: absolute;
    width: calc(100% - 32px);
    left: 0;
    margin: 24px 16px 0 16px;
    padding: unset;
    height: 56px;
    border-radius: 16px;
  }

  header.is-active .mw-header-wrap {
    height: 180px;
  }

  header.is-active .mw-header-wrap .burger-sticks + .text-wrap {
    display: flex;
    position: absolute;
    justify-content: center;
    top: 60px;
    gap: unset;
  }
  
  header.is-active .text-wrap .header-link-wrap {
    font-weight: 500;
    font-size: 18px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  
  header.is-active .text-wrap .header-link-wrap a:nth-child(1) {
    animation: fadein 0.8s;
    animation-fill-mode: forwards;
  }
  
  header.is-active .text-wrap .header-link-wrap a:nth-child(2) {
    animation: fadein 0.9s;
    animation-fill-mode: forwards;
  }
  
  header.is-active .text-wrap .header-link-wrap a:nth-child(3) {
    animation: fadein 1s;
    animation-fill-mode: forwards;
  }

  header > div .logo-wrap {
    width: unset;
    margin-top: 12px;
    position: absolute;
  }

  header > div .logo-wrap img {
    width: 56px;
  }

  main {
    margin-top: unset;
  }

  main img:hover {
    pointer-events: none;
  }

  main .main-body .padding-wrap {
    padding: 40px 24px;
  }

  main .main-body .blueberry-seedlings-sale-wrap.padding-wrap {
    padding-bottom: 80px;
  }

  main.blueberry .main-body .padding-wrap,
  main.caring .main-body .padding-wrap {
    padding: 40px 16px;
  }

  .sub-padding-wrap {
    padding: 0 8px;
  }

  .sub-padding-wrap + img {
    margin-top: 24px;
  }

  img + .sub-padding-wrap {
    margin-top: 40px;
  }

  footer > div {
    display: block;
    text-align: center;
    align-items: center;
    padding: 80px 0;
    height: unset;
  }

  footer > div .logo-wrap {
    display: inline-block;
    width: 160px;
    margin-right: unset;
  }

  footer .text-wrap {
    display: block;
  }

  footer .text-wrap > div {
    margin-top: 40px;
    font-size: 16px;
    line-height: 130%;
  }

  footer .text-wrap > div > a {
    display: inline;
  }

  footer .text-wrap > div > a > p.title {
    font-size: 14px;
  }

  footer .text-wrap > div > a + a {
    margin-left: 16px;
  }

  footer .text-wrap p.title {
    font-size: 18px;
    font-weight: 500;
    line-height: 130%;
  }

  footer .text-wrap > div > p + p {
    margin-top: 8px;
  }

  footer .text-wrap a > .title {
    display: inline;
  }

  footer .text-wrap > div > a:last-child {
    margin-left: 16px;
  }

  footer .text-wrap .company-wrap {
    font-family: 'Noto Sans KR';
    font-size: 13px;
  }

  button {
    border-radius: 8px;
    font-weight: 500;
    padding: 12px 24px;
  }

  main .top-wrap {
    height: 500px;
  }
  
  main .top-wrap .main-text-wrap {
    font-size: 20px;
    padding-top: 80px;
    font-weight: 400;
  }

  main .top-wrap .main-text-wrap p + p {
    margin-top: 12px;
  }

  main .top-wrap .main-text-wrap .title {
    font-weight: 500;
  }

  main .top-wrap .main-text-wrap .title,
  main .top-wrap .main-text-wrap .title + p {
    line-height: 130%;
  }

  main .top-wrap .main-text-wrap .title,
  main .contents .title {
    font-size: 30px;
    font-weight: 500;
  }

  footer .text-wrap > div > p:nth-child(3),
  main .top-wrap .main-text-wrap .title + p,
  main .product-wrap div.product-wrap-list p,
  main .contents div p + p {
    margin-top: 16px;
  }

  main .product-wrap div.product-wrap-list {
    width: 100%;
    font-size: 20px;
  }

  main .top-wrap .main-text-wrap p + button {
    margin-top: 24px;
    border-radius: 8px;
    width: unset;
    height: unset;
    padding: 12px 24px;
    font-weight: 500;
  }

  main .green-color-title {
    font-size: 16px;
    font-weight: 500;
  }

  main .contents {
    font-size: 18px;
    padding: 80px 24px 40px 24px;
  }

  main .contents div .green-color-title + p {
    font-size: 20px;
    line-height: 130%;
    margin-top: 12px;
  }

  main .contents > div + div {
    margin-top: 40px;
  }

  main .contents .box-wrap {
    flex-direction: column;
    gap: 24px;
  }

  main .contents .box-wrap > div {
    width: 100%;
    height: unset;
    border-radius: 16px;
    aspect-ratio: 1;
  }

  main .contents .box-wrap > div:hover {
    border-radius: 16px;
  }

  main .about-wrap, main .product-wrap {
    height: unset;
    flex-direction: column;
  }
  
  main.home .product-list-wrap {
    padding: 80px 0 80px 16px;
  }

  .product-list-wrap img + p {
    font-size: 20px;
  }

  .swiper {
    height: 304px !important;
  }

  .swiper-slide {
    width: 240px !important;
    height: 240px !important;
  }

  main.shop .main-body {
    padding: 40px 16px;
  }

  main.shop .product-list-wrap img + p {
    margin-top: 12px;
  }

  main.caring .main-body {
    padding: unset;
  }

  main .top-wrap .main-text-wrap > div > p:nth-child(4) {
    margin-top: 1px;
  }

  .product-main-wrap .product-list-wrap > div {
    width: calc(100% / 2 - 4px);
    margin-bottom: 16px;
  }

  .product-list-wrap img,
  .product-list-wrap img:hover {
    border-radius: 16px;
  }

  .product-list-wrap img + p,
  .sub-sub-title {
    font-size: 16px;
  }

  .sub-title {
    font-size: 24px;
  }

  main .product-wrap {
    padding: 80px 16px;
  }

  main .product-wrap > div .sub-title {
    font-size: 30px;
    font-weight: 500;
    line-height: 130%;
  }

  main .product-wrap > div > p + p {
    font-size: 20px;
    font-weight: 400;
    line-height: 130%;
    margin-top: 16px;
    text-align: center;
  }

  .sub-title.lead-icon > span::before {
    background-image: url("./images/icon/lead_icon.svg");
    background-size: 36px 36px;
    width: 36px;
    height: 36px;
  }

  .sub-title + .sub-contents {
    margin-top: 24px;
  }

  .sub-contents {
    font-family: 'Pretendard';
    font-size: 20px;
    line-height: 150%;
  }

  .sub-sub-title + .sub-contents {
    margin-top: 12px;
  }

  .sub-contents li + li {
    margin-top: 8px;
  }

  .sub-title.lead-icon + .sub-contents,
  .sub-title.lead-icon + .sub-sub-title,
  .sub-contents + img,
  .notice-wrap + img {
    margin-top: 24px;
  }

  img + .sub-title,
  .table-wrap .table-header {
    margin-top: 40px;
  }

  .table-wrap .table-header,
  .table-wrap .table-box > div > .table-sub-title {
    font-size: 20px;
    border-radius: 8px;
  }

  .table-wrap .table-box > div .table-sub-contents {
    font-weight: 500;
    margin-top: 4px;
  }

  .table-wrap .table-header .table-icon {
    font-size: 14px;
  }

  .table-wrap .table-box {
    margin-top: 16px;
  }

  .table-wrap .table-box.four-box {
    flex-direction: column;
    align-items: unset;
  }

  .table-wrap .table-box.four-box > div {
    width: unset;
    padding: 24px 0;
  }

  .table-wrap .table-box.four-box > div.middle-size .table-sub-contents {
    display: flex;
    justify-content: center;
  }

  .table-wrap .table-box > div > .table-sub-contents {
    font-size: 16px;
  }

  .table-wrap .table-box > div {
    padding: 24px;
    font-size: 20px;
  }

  .table-wrap .table-box > div ul {
    margin-top: 4px;
  }

  .normal-wrap {
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
  }

  .normal-wrap > div:first-child,
  .normal-wrap > div:last-child,
  .blueberry-seedlings-sale-wrap .normal-wrap > div {
    width: unset;
  }

  .normal-wrap.half-and-half + p {
    display: none;
  }

  .blueberry-experience-wrap > div > div > div > p, 
  .blueberry-seedlings-sale-wrap .normal-wrap p, 
  .masonry-wrap p {
    display: block;
    margin-top: 12px;
    text-align: center;
    font-size: 18px;
  }

  .blueberry-seedlings-sale-wrap .normal-wrap {
    gap: 16px;
    margin-top: 24px;
    margin-bottom: 80px;
  }

  /* MW div 정렬 순서 변경 */
  .masonry-wrap {
    display: flex;
    flex-direction: column;
    column-count: unset;
    column-gap: unset;
  }

  .masonry-wrap > div {
    display: flex;
    margin-bottom: 16px;
  }

  .masonry-wrap > div:nth-child(1) {
    order: 0;
  }

  .masonry-wrap > div:nth-child(2) {
    order: 2;
  }

  .masonry-wrap > div:nth-child(3) {
    order: 4;
  }

  .masonry-wrap > div:nth-child(4) {
    order: 6;
  }

  .masonry-wrap > div:nth-child(5) {
    order: 1;
  }

  .masonry-wrap > div:nth-child(6) {
    order: 3;
  }

  .masonry-wrap > div:nth-child(7) {
    order: 5;
  }

  .masonry-wrap > div:nth-child(8) {
    order: 7;
  }

  .caring .masonry-wrap > div:last-child {
    margin-bottom: unset;
  }

  .caring .masonry-wrap > div:nth-child(2) {
    order: 3;
  }

  .caring .masonry-wrap > div:nth-child(3) {
    order: 2;
  }

  .notice-wrap > p.notice-title,
  .left-title-wrap > div > span {
    font-size: 18px;
  }

  .caring .table-wrap {
    margin-top: 40px;
  }

  .caring .table-wrap + div,
  .caring .sub-contents + .table-wrap,
  .sub-title + .left-title-wrap {
    margin-top: 24px;
  }

  main.blueberry .main-body .blueberry-farm-wrap > img,
  main.caring .main-body .caring-farm-top-wrap > img {
    border-radius: 8px;
  }

  .caring-farm-top-wrap .notice-wrap {
    margin-top: 8px;
  }

  .caring-farm-top-wrap .notice-wrap > p.notice-title,
  .left-title-wrap > div > span:last-child {
    font-size: 16px;
  }

  .table-wrap .table-box.four-box {
    gap: 16px;
  }

  .left-title-wrap > div {
    flex-direction: column;
    text-align: center;
  }

  .left-title-wrap > div + div {
    margin-top: 24px;
  }

  .left-title-wrap > div > span:last-child {
    margin-top: 8px;;
  }

  .left-title-wrap > div > span > span {
    display: block;
    font-size: 14px;
    margin-top: 4px;
  }

  main.caring .mountain-wrap img.pc-tag.hide {
    display: none;
  }

  main.caring .mountain-wrap img.mw-tag.hide {
    display: inherit;
    border-radius: unset;
  }

  .mountain-wrap {
    padding-top: 125px;
    margin: 0 -24px;
  }

  .blueberry-experience-wrap .masonry-wrap > div > img,
  .blueberry-experience-wrap .normal-wrap > div > img,
  .blueberry-seedlings-sale-wrap .normal-wrap > div > img,
  .caring-farm-top-wrap .masonry-wrap > div > img,
  .caring-farm-middle-wrap .masonry-wrap > div > img,
  .caring-farm-bottom-wrap .masonry-wrap > div > img {
    height: unset;
  }

  .blueberry-experience-wrap .masonry-wrap > div:nth-child(1) > img,
  .blueberry-experience-wrap .masonry-wrap > div:nth-child(5) > img {
    height: unset;
  }

  .caring div.farming-introduction-wrap {
    margin-top: 80px;
  }

  main.caring .main-body .caring-farm-bottom-wrap.padding-wrap {
    padding-bottom: 80px;
  }

  .caring-farm-top-wrap .main-image {
    height: auto;
  }

  .caring-farm-top-wrap .sub-sub-title,
  .caring-farm-middle-wrap .sub-sub-title {
    font-size: 20px;
  }

  .caring-farm-top-wrap .masonry-wrap > div:nth-child(2), 
  .caring-farm-top-wrap .masonry-wrap > div:last-child {
    margin-bottom: 16px;
  }

  .caring .caring-farm-bottom-wrap .table-wrap .table-box > div {
    align-items: unset;
    flex-direction: column;
    gap: 4px;
    line-height: 150%;
  }

  .caring .caring-farm-bottom-wrap .table-wrap .table-box > div .custom-import-text {
    position: unset;
    padding-left: unset;
    word-break: break-all;
  }
  
  .caring .caring-farm-bottom-wrap .table-wrap .table-box > div .custom-import-text::before {
    content: unset; /* 비어 있는 내용 */
    position: unset;
    left: unset;
    width: unset;
  }

  .masonry-wrap > div:last-child {
    margin-bottom: unset !important;
  }

  .table-wrap .table-box > div ul.custom-circle-list {
    list-style-type: none;
    list-style-image: none;
    padding-left: 0;
    line-height: 150%;
  }

  .table-wrap .table-box > div ul.custom-circle-list li {
    margin-left: 15px;
  }

  .table-wrap .table-box > div ul.custom-circle-list li:before {
    top: 12px;
    transform: none;
  }

  .table-wrap .table-box > div ul.custom-circle-list li::marker {
    content: none;
  }

  .masonry-wrap > div > img,
  .normal-wrap > div > img {
    aspect-ratio: 1;
    height: auto !important;
  }

  .sub-title.lead-icon + .sub-contents li::before {
    top: -1px;
  }
  
  .sub-title.lead-icon + .sub-contents li > p {
    padding-left: 12px;
  }

  .left-title-wrap > div > div {
    text-align: left;
    flex-wrap: wrap;
  }

  .left-title-wrap > div > div + div {
    margin-top: 24px;
  }

  .left-title-wrap div > span {
    font-size: 20px;
  }

  .left-title-wrap div > span + span {
    margin-top: 8px;
  }

  .left-title-wrap div .add-text-wrap {
    font-size: 16px;
  }
}